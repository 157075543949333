<template>
  <div class="register">
    <div class="logo-web">
      <img src="../assets/ll.png" alt="" width="100%" height="100%" />
    </div>    
    <Model-bonus
      :dialog="dialog"
      @onCloseDialog="closeDialog"
      :userInfo="userInfo"
    />
    <v-card width="90%" max-width="400" class="py-5 " rounded="xxl" color="#C39BD3"  >      <!-- สีฟอร์ม -->
      <h2 class="mb-2 text-center"  >สมัครสมาชิก</h2>
      <v-form class="form-custom" ref="form" @submit.prevent="handleSubmit">
        <!--  -->
        <p>หมายเลขโทรศัพท์</p>
        <v-text-field
          solo
          flat
          outlined
          
          dense
          ref="form.phoneNumber"
          v-model="phoneNumber"
          required
          counter
          :rules="[
            () => !!phoneNumber || 'โปรดเบอร์โทรศัทพ์',
            () =>
              (!!phoneNumber && phoneNumber.length === 10) ||
              'เบอร์มือถือไม่ถูกต้อง',
          ]"
          maxlength="10"
          label="หมายเลขโทรศัพท์"
          prepend-inner-icon="mdi-phone"
        ></v-text-field>
        <!--  -->
        <p>ธนาคารสำหรับฝาก - ถอนเงิน <b>*</b></p>
        <v-select
          :items="styles.bankName"
          solo
          outlined
          dense
          flat
          :rules="[() => !!bankName || 'โปรดเลือกธนาคาร']"
          v-model="bankName"
          required
          label="เลือกธนาคาร"
        ></v-select>
        <!--  -->
        <p>เลขที่บัญชี</p>
        <v-text-field
          solo
          flat
          outlined
          dense
          v-model="bankNumber"
          :rules="[
            () => !!bankNumber || 'โปรดกรอกเลขที่บัญชี',
            () =>
              (!!bankNumber && bankNumber.length >= 10) ||
              'เลขที่บัญชีไม่ถูกต้อง',
          ]"
          required
          maxlength="15"
          label="เลขที่บัญชี"
        ></v-text-field>
        <!--  -->
        <p>ชื่อจริง</p>
        <v-text-field
          solo
          flat
          outlined
          dense
          v-model="fristname"
          :rules="[() => !!fristname || 'โปรดกรอกชื่อ']"
          required
          label="ชื่อจริง"
        ></v-text-field>
        <!--  -->
        <p>นามสกุล</p>
        <v-text-field
          solo
          flat
          outlined
          dense
          v-model="lastname"
          :rules="[() => !!lastname || 'โปรดกรอกนามสกุล']"
          required
          label="นามสกุล"
        ></v-text-field>
            <v-btn
              x-large
              type="submit"
              color=#A569BD 
              class="mb-4"
              dark width="100%"
              ><v-icon left>mdi-account-plus</v-icon>
               สมัครสมาชิก
          </v-btn>

          <v-btn 
              medium 
              color="null"
              dark
              width="100%"
              @click="handleLogin"
              ><v-icon left>mdi-gamepad-variant</v-icon>
              
              เข้าสู่ระบบ
          </v-btn>

      </v-form>
    </v-card>
  </div>
</template>

<script>

import ModelBonus from "../components/ModelBonus.vue";
export default {
  components: { ModelBonus },
  data: () => ({
    phoneNumber: null,
    bankName: null,
    bankNumber: null,
    fristname: null,
    lastname: null,
    styles: {
      bankName: [
        "ธนาคารไทยพาณิชย์",
        "ธนาคารกสิกรไทย",
        "ธนาคารกรุงเทพ",
        "ธนาคารกรุงไทย",
        "ธนาคารออมสิน",
        "ธนาคารกรุงศรีอยุธยา",
        "ธนาคารซิตี๊แบงก์",
        "ธนาคารเพื่อการเกษตรและสหกรณ์แห่งการเกษตร",
        "ธนาคารทีเอ็มบีธนชาต",
        "ธนาคารเกียรตินาคิน",
        "ธนาคารไอซีบีซี (ไทย)",
        "ธนาคารเครดิตเพื่อรายย่อย",
        "ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)",
        "ธนาคารไอเอ็มบีไทย",
        "ธนาคารยูโอบี",
        "ธนาคารเอชเอสบีซีประเทศไทย",
        "ธนาคารมิซูโฉ คอร์ปอเรต",
        "ธนาคารอาหารสงเคราะห์",
        "ธนาคารแลนด์ แอนด์ เฮ้าส์",
        "ธนาคารทิสโก้",
        "ธนาคารอิสลามแห่งประเทศไทย",
      ],
    },
    dialog: false,
    userInfo: "",
  }),
  computed: {
    form() {
      return {
        phoneNumber: this.phoneNumber,
        bankName: this.bankName,
        bankNumber: this.bankNumber,
        fristname: this.fristname,
        lastname: this.lastname,
      };
    },
  },
  methods: {
    handleLogin() {
      this.$router.push('/Login'); // เปลี่ยนเส้นทางไปยังหน้า Login.vue
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
      this.dialog = true;
      this.userInfo = {
        phoneNumber: this.phoneNumber,
        fristname: this.fristname,
        lastname: this.lastname,
      };
      }
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-web {
  width: 100px;
  height: 100px;
  margin-bottom: 50px;
  transform: scale(2); // เพิ่มขนาดโลโก้ ... เท่า
  img {
    object-fit: cover;
  }
}
.register {
  height: 100%;
  width: 100vw;

  // สีพื้นหลัง (ไล่สี)
  /* background: radial-gradient(at 0% 100%, #be643a, rgba(0, 0, 0, 0) 40vw), radial-gradient(at 100% 0%, #5f5f5f, rgba(0, 0, 0, 0) 40vw) ; */
  background-image: url(../assets/bg.jpg);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  background-size: cover, contain;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  p {
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
  }
  h2 {
    font-weight: 400;
    letter-spacing: 1.2px;
    color: #fff ;  // สีหัวข้อความ สมัครสมาชิก
  }
  p {
    font-size: 15px;
    b {
      color: rgb(255, 0, 0);
    }
  }
}
.form-custom {
  padding: 0 15px;
}
.bg-card{
  background-image: url("~@/assets/ll.png");
  background-size: cover, contain;
}

</style>